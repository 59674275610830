.epb-service {
  &-grid-size {
    height: 100vh;
    min-height: 100%;
    overflow-y: auto;
  }
  &-menu-icon {
    width: 40px;
    height: 40px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
  }
  &-tab-selected {
    display: inline-block;
    width: auto;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    margin-top: 7px;
    color: #000000;
    border-bottom: 2px solid #7da5c2;
    cursor: pointer;
  }
  &-tab-unSelected {
    width: 95px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9b9b9b;
    cursor: pointer;
  }
  &-button {
    width: 96px;
    height: 42px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    background-color: #7CBF47;
    border: 1px solid #7da5c2;
    border-radius: 10px;
  }
  &-checkbox {
    &-outline {
      width: 14px;
      height: 14px;
      border: 1.5px solid #7da5c2;
      cursor: pointer;
    }
    &-filled {
      width: 14px;
      height: 14px;
      background: #7da5c2;
      cursor: pointer;
      &-image {
        width: 10px;
        height: 10px;
      }
    }
  }

  &-Category-heading {
    width: auto;
    height: 33px;
    background: #dde6ec;
    margin-left: -10px;
    &-arrow-size{
      width: 10px;
      height: 10px;
      margin: 0px 10px;
    }
    &-image-size {
      width: 19px;
      height: 19px;
      align-self: center;
    }
    &-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      margin-left: 10px;
      color: #000000;
    }
  }
  &-insulation {
    &-start-line {
      width: 7px;
      height: 95%;
      margin-left: 90%;
      margin-right: 5%;
      margin-top: 2%;
      background: #dde6ec;
    }
    &-category-name {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      color: #000000;
    }
  }
}

@media only screen and (max-width: 900px){
  .epb-service-menu-icon{
    width: 35px;
    height: 35px;
  }
}
