.center-text {
  text-align: center;
}
.left-text{
text-align: left;
}
.right-text{
text-align: right;
}

.start-text {
  text-align: start;
}

.text {
  &-direction {
    &-rtl {
      direction: rtl;
    }
  }

  &-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-align {
    &-center {
      text-align: center;
    }

    &-end {
      text-align: end;
    }

    &-start {
      text-align: start;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.line-height {
  &-01 {
    line-height: 0.1;
  }

  &-02 {
    line-height: 0.2;
  }

  &-03 {
    line-height: 0.3;
  }

  &-04 {
    line-height: 0.4;
  }

  &-05 {
    line-height: 0.5;
  }

  &-06 {
    line-height: 0.6;
  }

  &-07 {
    line-height: 0.7;
  }

  &-08 {
    line-height: 0.8;
  }

  &-09 {
    line-height: 0.9;
  }

  &-10 {
    line-height: 1;
  }

  &-11 {
    line-height: 1.1;
  }

  &-12 {
    line-height: 1.2;
  }

  &-13 {
    line-height: 1.3;
  }

  &-14 {
    line-height: 1.4;
  }

  &-15 {
    line-height: 1.5;
  }

  &-16 {
    line-height: 1.6;
  }

  &-1-p {
    line-height: 1px;
  }

  &-2-p {
    line-height: 2px;
  }

  &-3-p {
    line-height: 3px;
  }
  
  &-4-p {
    line-height: 4px;
  }

  &-5-p {
    line-height: 5px;
  }

  &-6-p {
    line-height: 6px;
  }

  &-7-p {
    line-height: 7px;
  }

  &-8-p {
    line-height: 8px;
  }

  &-9-p {
    line-height: 9px;
  }
  
  &-10-p {
    line-height: 10px;
  }

  &-11-p {
    line-height: 11px;
  }

  &-12-p {
    line-height: 12px;
  }

  &-13-p {
    line-height: 13px;
  }

  &-14-p {
    line-height: 14px;
  }

  &-15-p {
    line-height: 15px;
  }

  &-16-p {
    line-height: 16px;
  }

  &-17-p {
    line-height: 17px;
  }

  &-18-p {
    line-height: 18px;
  }

  &-19-p {
    line-height: 19px;
  }

  &-20-p {
    line-height: 20px;
  }
}

.font-size-1 {
  font-size: 1px;
}
.font-size-2 {
  font-size: 2px;
}
.font-size-3 {
  font-size: 3px;
}
.font-size-4 {
  font-size: 4px;
}
.font-size-5 {
  font-size: 5px;
}
.font-size-6 {
  font-size: 6px;
}
.font-size-7 {
  font-size: 7px;
}
.font-size-8 {
  font-size: 8px;
}
.font-size-9 {
  font-size: 9px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-17 {
  font-size: 17px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-19 {
  font-size: 19px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-21 {
  font-size: 21px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-23 {
  font-size: 23px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-25 {
  font-size: 25px;
}
.font-size-26 {
  font-size: 26px;
}
.font-size-27 {
  font-size: 27px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-29 {
  font-size: 29px;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-31 {
  font-size: 31px;
}
.font-size-32 {
  font-size: 32px;
}
.font-size-33 {
  font-size: 33px;
}
.font-size-34 {
  font-size: 34px;
}
.font-size-35 {
  font-size: 35px;
}
.font-size-36 {
  font-size: 36px;
}
.font-size-37 {
  font-size: 37px;
}
.font-size-38 {
  font-size: 38px;
}
.font-size-39 {
  font-size: 39px;
}
.font-size-40 {
  font-size: 40px;
}
.font-size-41 {
  font-size: 41px;
}
.font-size-42 {
  font-size: 42px;
}
.font-size-43 {
  font-size: 43px;
}
.font-size-44 {
  font-size: 44px;
}
.font-size-45 {
  font-size: 45px;
}
.font-size-46 {
  font-size: 46px;
}
.font-size-47 {
  font-size: 47px;
}
.font-size-48 {
  font-size: 48px;
}
.font-size-49 {
  font-size: 49px;
}
.font-size-50 {
  font-size: 50px;
}
.font-size-51 {
  font-size: 51px;
}
.font-size-52 {
  font-size: 52px;
}
.font-size-53 {
  font-size: 53px;
}
.font-size-54 {
  font-size: 54px;
}
.font-size-55 {
  font-size: 55px;
}
.font-size-56 {
  font-size: 56px;
}
.font-size-57 {
  font-size: 57px;
}
.font-size-58 {
  font-size: 58px;
}
.font-size-59 {
  font-size: 59px;
}
.font-size-60 {
  font-size: 60px;
}
.font-size-61 {
  font-size: 61px;
}
.font-size-62 {
  font-size: 62px;
}
.font-size-63 {
  font-size: 63px;
}
.font-size-64 {
  font-size: 64px;
}
.font-size-65 {
  font-size: 65px;
}
.font-size-66 {
  font-size: 66px;
}
.font-size-67 {
  font-size: 67px;
}
.font-size-68 {
  font-size: 68px;
}
.font-size-69 {
  font-size: 69px;
}
.font-size-70 {
  font-size: 70px;
}
.font-size-71 {
  font-size: 71px;
}
.font-size-72 {
  font-size: 72px;
}
.font-size-73 {
  font-size: 73px;
}
.font-size-74 {
  font-size: 74px;
}
.font-size-75 {
  font-size: 75px;
}
.font-size-76 {
  font-size: 76px;
}
.font-size-77 {
  font-size: 77px;
}
.font-size-78 {
  font-size: 78px;
}
.font-size-79 {
  font-size: 79px;
}
.font-size-80 {
  font-size: 80px;
}
.font-size-81 {
  font-size: 81px;
}
.font-size-82 {
  font-size: 82px;
}
.font-size-83 {
  font-size: 83px;
}
.font-size-84 {
  font-size: 84px;
}
.font-size-85 {
  font-size: 85px;
}
.font-size-86 {
  font-size: 86px;
}
.font-size-87 {
  font-size: 87px;
}
.font-size-88 {
  font-size: 88px;
}
.font-size-89 {
  font-size: 89px;
}
.font-size-90 {
  font-size: 90px;
}
.font-size-91 {
  font-size: 91px;
}
.font-size-92 {
  font-size: 92px;
}
.font-size-93 {
  font-size: 93px;
}
.font-size-94 {
  font-size: 94px;
}
.font-size-95 {
  font-size: 95px;
}
.font-size-96 {
  font-size: 96px;
}
.font-size-97 {
  font-size: 97px;
}
.font-size-98 {
  font-size: 98px;
}
.font-size-99 {
  font-size: 99px;
}
.font-size-100 {
  font-size: 100px;
}
