.step-four {
  &-header {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
  }
  &-data {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &-add-image {
    &-size {
      width: 15px;
      height: 15px;
    }
    width: 35px;
    height: 35px;
    margin-left: -5px;
    border-radius: 5px;
    background: #0066a0;
  }
  &-delete-image {
    width: 35px;
    height: 35px;
    margin-left: -5px;
  }
}
