.custom-date {
  &-input-label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 38px;
    color: #6f6f6f;
  }

  &-input-container {
    border: 1px solid #dcdcdc;
    line-height: 1.5rem;
    height: 45px;
    width: 90%;
    border-radius: 10px;
    color: #000000;
    padding: 6px 10px;
    background: #ffffff;
  }

  &-disable-input-container {
    width: 85%;
    border: none;
    background: none;
  }

  &-input-box {
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin: 10px;
  }

  &-error-border {
    border: 1px #f30c0c solid;
  }
}