.all-users {
    &-main-div {
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        // height: 100vh;
        height: calc(100vh - 65px);
        background-color: rgba(248,248,248,1)
    }
    &-card-container {
        margin-top: 10px;
        margin-bottom: 10px;
        height: calc(100vh - 190px);
        overflow-y: auto;
    }
    &-card-main {
        min-height: 50px;
        padding-left: 10px;
        max-height: 50px;
    }
    &-header-main {
        padding-left: 10px;
        margin-top: 20px;
        height: 25px;
    }
    &-header-item {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 14%;
        align-items: center;
        cursor: pointer;
    }
    &-search-bar {
        display: flex;
        flex-direction: row;
        height: 42px;
        width: 90%;
        padding: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        border: 1px solid #DCDCDC;
        align-items: center;
    }
    &-search-icon {
        height: 20px;
        width: 20px;
        margin-left: 5px;
    }
    &-verticle-line {
        height: 50px;
        width: 2px;
        background-color: #AAB7C4;
    }
    &-word-break {
        width: 100%;
        overflow-wrap: break-word;
    }
    &-icon-button {
        height: 35px;
        width: 35px;
        cursor: pointer;
    }
    &-card-item {
        display: flex;
        flex-direction: row;
        width: 14%;
        height: 100%;
        align-items: center;
        padding-right: 8px;
    }
    &-icon-close {
        height: 15px;
        width: 15px;
    }
    &-view-popup {
        padding-top: 10px;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 5%;
    }
    &-add-user {
        height: 100%;
        width: 100%;
        color: #ffffff;
        background-color: #7da5c2;
        height: 42px;
        border-radius: 6px;
    }
}
