.message-panel {
    width: 100%;
    height: 75vh;
    background: white;
    border-radius: 10px;
    border: solid 1px #D4D4D4;
    overflow: hidden;
    z-index: 2;

    &-emoji {
       position: absolute;
       width: 100%;
       height: 100%;
       bottom: 330px;
    }

    &-container {
        height: calc(100vh - 65px);
        width: 100%;
        background: rgba(248, 248, 248, 1);
    }

    &-profile {
        height: 30px;
        width: 30px;
        object-fit: contain;
        border-radius: 500px;
        background-color: rgba(0, 0, 0, 0);
        margin-right: 15px;
    }

    &-attachments {
        height: fit-content;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        display:flex;
        flex-direction: row;
        background-color: #c6e9f5;
        
        &-section {
            display:flex;
            flex-direction: row;
        }

        &-img {
            margin: 5px;
            height: 50px;
            width: 50px;
        }
        &-cross {
          margin-right:0px;
        }
    }

    &-chatAttachments {
        padding: 10px 25px;
        border-radius: 7px;
        margin: 1px;
        width: 434px;

        &-file {
            height: 30px;
            width: 30px;
            object-fit: contain;
        }
        
        &-img {
            width: 100%;
        }
        &-to {
            @extend .message-panel-chatAttachments;
            background-color: #7DA5C2;
            display: flex;
            justify-content: flex-end;
        }
        &-from {
            @extend .message-panel-chatAttachments;
            background-color: #193E5D;
            display: flex;
            justify-content: flex-start;
        }
    }

    &-message {
        min-height: 69px;
        height: fit-content;
        width: 434px;
        border-radius: 10px;
        background-color: #193E5D;
        padding: 10px 25px;

        &-alt {
            @extend .message-panel-message;
            background-color: #7DA5C2;
        }
    }

    &-search {
        &-container {
            height: 45px;
            width: 360px;
            border-radius: 10px;
            border: solid 1px #DCDCDC;
            background-color: white;
        }

        &-icon {
            height: 20px;
            width: 20px;
            object-fit: contain;
        }
    }

    &-building {
        height: 18px;
        width: 18px;
        object-fit: contain;
        background-color: rgba(0, 0, 0, 0);

        &-button {
            height: 45px;
            width: 45px;
            border: solid 1px #DCDCDC;
            background: white;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 15px;
            z-index: 9;
        }

        &-dropdown {
            height: calc(100% - 25px);
            width: calc(100% - 25px);
            border-radius: 10px;
            background: white;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
            padding: 5px 23px;

            &-container {
                position: absolute;
                width: 250px;
                height: 150px;
                top: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
            }

            &-arrow {
                height: 15px;
                width: 23.78px;
                z-index: 1;
            }

            &-item {
                height: 35px;
                cursor: pointer;
            }

            &-check {
                height: 14px;
                width: 14px;
                object-fit: contain;
            }

            &-line { 
                border-bottom: solid 1px #AAB7C4;
            }
        }
    }

    &-contacts {
        height: 100%;
        width: 100%;
        border-right: solid 1px #AAB7C4;
        overflow-y: auto;

        &-details {
            height: 87px;
            width: 100%;
            display: flex;
            cursor: pointer;

            &:hover {
                background-color: #E5EDF3;
            }

            &-notif {
                width: 8px;
                height: 8px;
                border-radius: 8px;
                margin-left: 15px;
            }

            &-profile {
                height: 40px;
                width: 40px;
                border-radius: 40px;
                background: rgba(0, 0, 0, 0);
                margin-left: 15px;
            }
        }
    }

    &-log {
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &-search {
            height: 45px;
            width: 360px;
            border-radius: 10px;
            border: solid 1px #DCDCDC;
            background-color: white;

            &-container {
                height: 70px;
                width: 100%;
                border-bottom: solid 1px #AAB7C4;
            }

            &-icon {
                height: 20px;
                width: 20px;
                object-fit: contain;
            }

            &-list {
                height: auto;
                background: white;
                border-radius: 10px;
                width: 100%;
                position: absolute;
                top: calc(100% + 25px);
                left: 0%;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                overflow: hidden;

                &-item {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    &:hover {
                        background: #E5EDF3;
                    }
                }
            }

            &-selected {

                &-contact {
                    height: 45px;
                    background: #E5EDF3;
                    border-radius: 7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 10px;
                    margin-left: 25px;
                }

                &-remove {
                    height: 12px;
                    width: 12px;
                    object-fit: contain;
                    margin-left: 25px;
                    cursor: pointer;
                }
            }
        }

        &-status {
            height: 15px;
            width: 15px;
            object-fit: contain;
            margin-left: 10px
        }
    }

    &-input {
        height: 100%;
        width: 100%;
        border: solid 1px #CCCCCC;
        border-radius: 5px;

        &-container {
            margin-top: 10px;
            height: 45px;
            width: 100%;
        }

        &-profile {
            height: 30px;
            width: 30px;
            background-color: rgba(0, 0, 0, 0);
            border-radius: 30px;
        }

        &-button {
            height: 45px;
            width: 100px;
            border-radius: 5px;
            background: #7DA5C2;
            cursor: pointer;
        }

        &-emoji {
            height: 19px;
            width: 19px;
            object-fit: contain;
            margin-left: 15px;
            margin-right: 15px;
            cursor: pointer;
        }

        &-attachment {
            height: 15px;
            width: 15px;
            object-fit: contain;
            margin-left: 15px;
            margin-right: 15px;
            cursor: pointer;
        }

        &-aero {
            height: 17px;
            width: 17px;
            object-fit: contain;
        }
    }

    &-new {
        &-message {
            height: 42px;
            width: 151px;
            background-color: #7DA5C2;
            border-radius: 10px;
        }
    }
}

@media only screen and (max-width: 540px) {
    .message-panel{
        &-input{
            &-button{
                width: 45px;
                height: 45px;
                border-radius: 25px;
            }
        }
    }
}