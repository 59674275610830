.notifications {
    &-main-div {
        padding-top: 15px;
        padding-left: 20px;
        width: 100%;
        height: calc(100vh - 65px);
        background-color: rgba(248,248,248,1)
    }
    &-card-container {
        margin-top: 10px;
        margin-bottom: 10px;
        height: calc(100vh - 190px);
        overflow-y: auto;
    }
    &-search-bar {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 100%;
        padding: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        border: 1px solid #DCDCDC;
        align-items: center;
    }
    &-card-main {
        min-height: 50px;
        padding-left: 10px;
        max-height: 50px;
    }
    &-search-icon {
        height: 20px;
        width: 20px;
        margin-left: 5px;
    }
    &-header-main {
        padding-left: 10px;
        margin-top: 20px;
        height: 25px;
        width: 100%;
    }
    &-header-item {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 16.5%;
        align-items: center;
        cursor: pointer;
    }
    &-header-item-sm {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 14%;
        align-items: center;
        cursor: pointer;
    }
    &-header-item-lg {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 19%;
        align-items: center;
        cursor: pointer;
    }
    &-vertical-div {
        display: flex;
        height: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }
    &-vertical-line-blue {
        height: 100%;
        width: 2px;
        background-color: #AAB7C4;
    }
    &-filter-div {
        display: flex;
        justify-content: center;
        width: 8%;
    }
    &-filter-active {
        height: 40px;
        width: 75px;
        background-color: #ffffff;
        color: black;
        border-radius: 6px;
        border: 1px solid #7DA5C2;
        cursor: pointer;
    }
    &-filter-inactive {
        height: 40px;
        width: 75px;
        color: #6F6F6F;
        cursor: pointer;
    }
    &-card-item {
        display: flex;
        flex-direction: row;
        width: 16.5%;
        height: 100%;
        align-items: center;
        padding-right: 8px;
    }
    &-card-item-sm {
        display: flex;
        flex-direction: row;
        width: 14%;
        height: 100%;
        align-items: center;
        padding-right: 8px;
    }
    &-card-item-lg {
        display: flex;
        flex-direction: row;
        width: 21%;
        height: 100%;
        align-items: center;
        padding-right: 8px;
    }
    &-word-break {
        width: 100%;
        overflow-wrap: break-word;
    }
    &-arrow-icon {
        height: 10px;
        width: 11px;
    }
    &-symbol-icon {
        height: 15px;
        width: 15px;
    }
    &-status-div {
        display: flex;
        width: 60%;
        align-items: center;
    }
    &-status-label {
        width: 50px;
    }
    &-button-icon {
        height: 35px;
        width: 35px;
    }
}

@media only screen and (max-width: 1024px) {
    .notifications {
        &-filter-div {
            width: 12%;
        }
    }
}
