.timeline {
    &-container {
        height: calc(100vh - 66px);
        width: 100%;
        background-color: white;
        padding: 0px 40px;
    }

    &-search {
        &-container {
            height: 40px;
            width: 303px;
            border-radius: 10px;
            border: solid 1px #DCDCDC;
            display: flex;
            align-items: center;
            background-color: white;
        }

        &-icon {
            height: 20px;
            width: 20px;
            object-fit: contain;
        }
    }

    &-planner {
        &-container {
            height: 700px;
            width: 100%;
            border: solid 1px #DADADA;
            border-radius: 10px;
            display: flex;
            background-color: white;
            overflow: hidden;
            
            &-sub {
                height: calc(100% - 48px);
                width: 100%;
                overflow-y: overlay;
                
            }
        }


        &-sidebar {
            width: 200px;
            height: fit-content;
            box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
            z-index: 9;

            &-month {
                height: 48px;
                width: 200px;
                border-bottom: solid 1px rgba(151,151,151,1);
                box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
                &-text {
                    width: 150px;
                }

                &-arrow {
                    height: 11px;
                    width: 11px;
                    object-fit: contain;
                    object-position: center;

                    &-left {    
                        @extend .timeline-planner-sidebar-month-arrow;
                        transform: rotate(90deg);
                    }

                    &-right {
                        @extend .timeline-planner-sidebar-month-arrow;
                        transform: rotate(-90deg);
                    }
                }
            }

            &-building {
                height: 58px;
                width: 100%;
                border-bottom: solid 1px rgba(225,225,225,1);

                &-modal {
                    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                    width: 276px;
                    height: fit-content;
                    border-radius: 10px;
                    background: white;
                    overflow: hidden;

                    &-container {
                        width: 595px;
                        position: absolute;
                        left: 100%;
                        top: calc(0% + 15px);
                    }

                    &-arrow {
                        margin-top: 15px;
                        height: 15px;
                        width: 23.78px;
                        z-index: 1;
                        transform: rotate(-90deg) translateY(4.5px);
                    }

                    &-item {
                        height: 50px;
                        width: 100%;
                        padding: 0px 15px;
                        align-items: center;
                        
                        &:hover {
                            background: #E5EDF3;
                        }
                    }

                    &-icon {
                        height: 25px;
                        width: 25px;
                        object-fit: contain;
                        object-position: top;
                        margin-left: 15px;
                    }
                    
                    &-border {
                        border-bottom: solid 1px #CCC;
                    }

                    &-date {
                        width: 270px;
                        height: 410px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-left: 25px;
                    }

                    &-save {
                        height: 42px;
                        border-radius: 10px;
                        cursor: pointer;
                        width: 100%;
                        background: #7DA5C2;
                    }
                }
            }
        }

        &-viewport {
            width: calc(100% - 200px);
            height: 100%;
            z-index: 3;

            &-week {
                height: 48px;
                display: flex;
                flex: 1;
                border-bottom: solid 1px rgba(151,151,151,1);
                display: flex;
                justify-content: center;
                align-items: center;

                &-border {
                    border-left: solid 1px #E1E1E1;
                }
            }

            &-service {
                &-block {
                    height: 58px;
                    flex: 1;
                    border-bottom: solid 1px #E1E1E1;

                    &-border {
                        border-left: solid 1px #E1E1E1;
                    }
                }
            }

            &-card { 
                height: 36px;
                border-radius: 3px;
                width: 77px;
                background-color: #334960;
                flex-shrink: 0;
                cursor: pointer;

                &-row {
                    width: 100%;
                    height: 58px;
                    display: flex;
                    align-items: center;
                }

                &-box { 
                    height: 100%;
                    width: calc(100% / 7);
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                }
            }
             
            &-card-modal {
                background-color: white;
                width: calc(100% - 20px);
                height: 97px;
                border-radius: 10px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                padding: 15px 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-container {
                    width: 230px;
                    height: 120px;
                    position: absolute;
                    top: 100%;
                    left: 0%;
                    align-items: center;
                    overflow: hidden;
                }

                &-arrow {
                    height: 15px;
                    width: 23.78px;
                    margin-left: 25px;
                    z-index: 1;
                }

                &-color {
                    height: 14px;
                    width: 14px;
                    border-radius: 14px;
                    background-color: #334960;
                    margin-right: 7px;
                }
            }
        }
    }
}

@-moz-document url-prefix() {
    .timeline-planner-container-sub {
        overflow-y: auto;
    }
}