.invoice-all {
  &-main-div {
    padding-top: 15px;
    padding-left: 20px;
    width: 100%;
    height: calc(100vh - 65px);
    background-color: rgba(248, 248, 248, 1);
  }
  &-first-part-width{
    display: flex;
    align-items: center;
    width: 80%;
  }
  &-second-part-width{
    display: flex;
    width: 20%;
  }
  &-card-container {
    margin-top: 10px;
    margin-bottom: 10px;
    height: calc(100vh - 195px);
    overflow-y: auto;
  }
  &-search-bar {
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 100%;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    align-items: center;
  }
  &-card-main {
    min-height: 50px;
    padding-left: 10px;
    max-height: 50px;
  }
  &-search-icon {
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }
  &-header-main {
    padding-left: 10px;
    margin-top: 20px;
    height: 25px;
    width: 78.5%;
  }
  &-header-item {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 17%;
    align-items: center;
    cursor: pointer;
  }
  &-header-item-sm {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 13%;
    align-items: center;
    cursor: pointer;
  }
  &-header-item-lg {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 22%;
    align-items: center;
    cursor: pointer;
  }
  &-vertical-div {
    display: flex;
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
  }
  &-vertical-line-blue {
    height: 100%;
    width: 2px;
    background-color: #aab7c4;
    margin-right: 2px;
  }
  &-filter-div {
    display: flex;
    justify-content: center;
    width: 8%;
  }
  &-filter-active {
    height: 40px;
    width: 90px;
    background-color: #ffffff;
    color: black;
    border-radius: 6px;
    border: 1px solid #7da5c2;
    cursor: pointer;
    justify-content: center;
  }
  &-filter-inactive {
    height: 40px;
    width: 75px;
    color: #6f6f6f;
    cursor: pointer;
  }
  &-card-item {
    display: flex;
    flex-direction: row;
    width: 17%;
    height: 100%;
    align-items: center;
    padding-right: 8px;
  }
  &-card-item-sm {
    display: flex;
    flex-direction: row;
    width: 13%;
    height: 100%;
    align-items: center;
    padding-right: 8px;
  }
  &-card-item-lg {
    display: flex;
    flex-direction: row;
    width: 22%;
    height: 100%;
    align-items: center;
    padding-right: 8px;
  }
  &-word-break {
    width: 100%;
    overflow-wrap: break-word;
  }
  &-arrow-icon {
    height: 10px;
    width: 11px;
  }
  &-symbol-icon {
    height: 15px;
    width: 15px;
    margin-left: 10px;
  }
  &-status-div {
    display: flex;
    width: 53%;
    justify-content: flex-start;
    margin-left: 5px;
    margin-right: 5px;
  }
  &-status {
    // &-label {
    //   width: 50px;
    // }
    &-blue-dot {
      width: 11px;
      height: 11px;
      border-radius: 5px;
      background: #b2d5ff;
    }
    &-yellow-dot {
      width: 11px;
      height: 11px;
      border-radius: 5px;
      background: #ffebaa;
    }
    &-green-dot {
      width: 11px;
      height: 11px;
      border-radius: 5px;
      background: #cef1b2;
    }
    &-purple-dot{
      width: 11px;
      height: 11px;
      border-radius: 5px;
      background: #686886;
    }
    &-red-dot{
      width: 11px;
      height: 11px;
      border-radius: 5px;
      background: #D70000;
    }
  }
  &-button-icon {
    height: 30px;
    width: 30px;
  }

  &-new-facturate {
    height: 42px;
    width: 155px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    &-button {
      background-color: #7DA5C2;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
    }

    &-modal {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background-color: white;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);

      &-container {
        height: 215px;
        width: 600px;
        position: absolute;
        top: calc(100% + 15px);
        z-index: 9;
        cursor: default;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .invoice-all {
    &-filter-div {
      width: 12%;
    }
  }
}
