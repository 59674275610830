.ventilation-service-step2 {
    &-horizontal-line{
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
    }
    &-info-icon {
        height: 15px;
        width: 15px;
    }
    &-info-div {
        display: flex;
        padding-right: 10px;
        padding-left: 10px;
    }
}