.company-edit {
    &-main-div {
        padding: 15px 20px;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 65px);
        background-color: rgba(248,248,248,1)
    }
    &-sub-div {
        height: 100%;
        width: 25%;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
    }
    &-child-div {
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #DADADA;
    }
    &-add-button {
        height: 40px;
        width: 140px;
        border-radius: 8px;
        border: 1px solid #DCDCDC;
        cursor: pointer;
    }
    &-button-edit {
        height: 40px;
        width: 77px;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        cursor: pointer;
    }
    &-button-icon {
        height: 11px;
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
    }
    &-button-save {
        height: 40px;
        width: 77px;
        color: #ffffff;
        background-color: #7DA5C2;
        border-radius: 6px;
        cursor: pointer;
    }
    &-button-cancel {
        height: 40px;
        width: 77px;
        color: #ffffff;
        background-color: #C29A7D;
        border-radius: 6px;
        cursor: pointer;
    }
    &-div-save {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
    }
    &-span-height {
        min-height: 16px;
    }
}


@media only screen and (max-width: 1050px) and (min-width: 901px) {
    .company-edit {
        &-sub-div {
            width: 50%;
        }
    }
}

@media only screen and (max-width: 900px) {
    .company-edit {
        &-sub-div {
            width: 100%;
        }
    }
}
