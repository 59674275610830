.externe {
    &-container {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    
    &-main {
        &-container {
            height: calc(100vh - 65px);
            width: 100%;
            padding: 25px;
        }
    }

    &-input {
        width: 100%;
        margin-left: 15px;

        &-container {
            height: 40px;
            border-radius: 10px;
            width: 100%;
            border: solid 1px #DCDCDC;
            padding: 0px 15px;
            display: flex;
            margin-bottom: 15px;
        }

        &-icon {
            height: 20px;
            width: 20px;
            object-fit: contain;
        }
    }

    &-view {
        &-container {
            height: calc(100vh - 180px);
            width: 100%;
            display: flex;
        }

        &-column {
            height: 100%;
            border-radius: 10px;
            border: 1px solid #D4D4D4;
            overflow: hidden;

            &-container {
                height: 100%;
            }

            &-user {
                @extend .externe-view-column;
                width: 100%;

                &-container {
                    width: 25%;
                }
            }

            &-company {
                @extend .externe-view-column;
                width: 100%;

                &-container {
                    width: 30%;
                    margin: 0px 15px;
                }
            }

            &-file {
                @extend .externe-view-column;
                width: 100%;

                &-container {
                    width: 45%;
                }
            }

            &-exists {
                height: 10px;
                width: 10px;
                border-radius: 10px;
                background-color: #7DA5C2;
            }

            &-item {
                height: 87px;
                width: 100%;
                padding: 0px 25px;

                &:hover {
                    background: #E5EDF3;
                }
            }

            &-profile {
                height: 50px;
                width: 50px;
                border-radius: 25px;
                object-fit: cover;
                margin-right: 15px;

                &-blank {
                    height: 40px;
                    width: 40px;
                    border-radius: 50px;
                    background-color: #DCDCDC;

                    &-icon {
                        height: 15px;
                        width: 15px;
                        object-fit: contain;
                    }
                }
            }

            &-enable {
                height: 25px;
                width: 25px;
                border-radius: 15px;
                border: solid 2px #7DA5C2;
            }
        }

        &-mobile {
            &-back {
                height: 40px;
                border-radius: 10px;
                width: fit-content;
                padding: 0px 15px;
                background-color: #7DA5C2;
                margin-bottom: 15px;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .externe {
        &-input {
            &-container {
                width: 100%;
            }
        }

        &-view {
            &-container {
                flex-direction: column;
            }

            &-column {
                &-user {
                    width: 100%;
                    height: 50%;
                }

                &-company {
                    margin-top: 15px;
                    width: 100%;
                    height: 50%;
                    margin-left: 0px;
                }

                &-file {
                    width: 100%;
                    height: calc(100% - 55px);
                }
            }
        }
    }
}