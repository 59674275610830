.residence{
  &-grid-height{
    height: 100vh;
  }
   &-half-grid-height{
    height: 45%;
  }
    &-line-between {
      width: 0px;
      height: 85vh;
      // margin-top: 25px;
    
      border: 1px solid #aab7c4;
      background-color: #aab7c4;
    }
  }
  @media only screen and (max-width: 960px) {
    .residence-line-between {
      width: 91%;
      height: 0px;
      margin-bottom: 10%;
    }
  }
  