.step-two {
  &-description {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
  &-other-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }
  &-file-outer-container {
    // max-height: 60%;
    width: 100%;
    overflow: hidden;
  }
  &-file-inner-container {
    min-height: 5vh;
    // max-height: 60%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
