.profile-view-size {
  width: 664px;
  height: 439px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}
.profile {
  &-container-size {
    width: 630px;
    height: 58px;
    padding: 20px;
  }
  &-user-icon {
    width: 15px;
    height: 17px;
    margin-right: 8px;
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  &-cross-icon {
    width: 15px;
    height: 15px;
  }
  &-image {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    object-fit: contain;
  }
  &-data-view{
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px;
  }
}
