@import '../scss/design.scss';

// partial imports below

@import "./dashboard/header/_header.scss";
@import "./dashboard/services/_services.scss";
@import './dashboard/_dashboard.scss';
@import './client/_client.scss';
@import './client/add_client/_add_client.scss';
@import './client/add_contact_person/_add_contact_person.scss';
@import './shared/popup/_custom_popup.scss';
@import '../components/client/view_client/_view_client.scss';
@import '../components/residence/_residence.scss';
@import '../components/residence/components/actions_information/_actions_informations.scss';
@import '../components/residence/components/residence_amboise/_residence_amboise.scss';
@import '../components/residence/components/residence_amboise_actions/_residence_amboise_actions.scss';
@import '../components/residence/components/actions_information/document_details/_document_details.scss';
@import '../components/residence/components/actions_information/emails_tab/_emails_tab.scss';
@import '../components/residence/components/actions_information/info_tab/_info_tab.scss';
@import '../components/dashboard/users/_users.scss';
@import '../components/dashboard/message_panel/_messagepanel.scss';
@import '../components/dashboard/documents/_documents.scss';
@import '../components/dashboard/company_edit/_company_edit.scss';
@import '../components/client/private_client/_private_client.scss';
@import '../components/client/add_contact_person/_add_contact_person.scss';
@import '../components/shared/error/_error.scss';
@import '../components/shared/delete_confirm/_delete_confirm.scss';
@import '../components/dashboard/notifications/_notifications.scss';
@import '../components/dashboard/planning/_planning.scss';
@import '../components/dashboard/planning/calendar/_calendar.scss';
@import '../components/dashboard/planning/timeline/_timeline.scss';
@import '../components/dashboard/facturatie/invoice_new/_invoice_new.scss';
@import '../components/client/view_private_client/_view_private_client.scss';
@import '../components/dashboard/mobile_view/_mobile_view.scss';
@import '../components/dashboard/message_panel/message_mobile/_message_mobile.scss';
@import '../components/dashboard/externe/_externe.scss';
@import '../components/residence/components/email_modal/_email_modal.scss';
@import '../components/residence/components/document_selector/_document_selector.scss';
@import '../components/epb_forms/epb_form_admin/step_two/_step_two.scss';
@import '../components/epb_forms/epb_form_admin/step_one/_step_one.scss';

.font-color {
  &-black {
    color: black;
  }

  &-grey {
    color: #6f6f6f;
  }
  &-semi-dark-grey {
    color: #6f6f6f;
  }
  &-light-grey {
    color: #9B9B9B;
  }
  &-tan {
    color: #cebd87;
  }

  &-blue {
    color: #508cd0;
  }

  &-navy-blue {
    color: #193e5d;
  }

  &-sky-blue {
    color: #7da5c2;
  }

  &-green {
    color: #7bb84d;
  }

  &-purple {
    color: #686886;
  }

  &-pink {
    color: #b30419;
  }

  &-red {
    color: #f30c0c;
  }
  
  &-white {
    color: white;
  }

  &-dark-grey {
    color: #4A4A4A;
  }
}

.background-color {
  &-white {
    background: white;
  }

  &-sky-blue{
    background: #7DA5C2;
  }
  &-grey {
    background: #6f6f6f;
  }
  &-light-grey {
    background: #f3f3f3;
  }
  &-faded {
    &-blue {
      background: #b2d5ff;
    }

    &-tan {
      background: #ffebaa;
    }

    &-green {
      background: #cef1b2;
    }

    &-purple {
      background: #afafd2;
    }

    &-pink {
      background: #fba1ac;
    }
    &-transparent {
      background: transparent;
    }
    &-red {
      background-color: #f30c0c;
    }

    &-faint-grey {
      background: rgba(248, 248, 248, 1);
    }
  }
}

.custom-button {
  &-blue {
    height: 100%;
    width: 40%;
    color: #ffffff;
    background-color: #7da5c2;
    height: 42px;
    border-radius: 6px;
    cursor: pointer;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-disable {
    cursor: not-allowed;
  }
}
.input-custom-control {
  border: 1px #dadada solid;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  color: #000000;
  padding: 6px 6px;
}
.input-error-border {
  border: 1px #f30c0c solid;
}
.core-custom {
  &-button {
    line-height: 1.5rem;
    border-radius: 4px;
    padding: 8px 6px;
    background-color: #7da5c2;
    color: #ffffff;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 900px) {
  .custom-button {
    &-blue {
      height: 100%;
      width: 100%;
      color: #ffffff;
      background-color: #7da5c2;
      border-radius: 6px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiDialog-root {
  z-index: 9999999 !important;
}

// animations here 
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #7da5c2;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
