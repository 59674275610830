.view-client {
    &-main-div {
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 10px 20px;
        border: 1px solid #DADADA;
    }
    &-img-div {
        max-height: 150px;
    }
    &-img-div-default {
        height: 100px;
        width: 100px;
    }
    &-edit-button {
        height: 40px;
        width: 77px;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        cursor: pointer;
    }
    &-check-icon {
        height: 11px;
        width: 13px;
        margin-right: 5px;
    }
    &-div-save {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
    }
    &-button-icon {
        height: 11px;
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
    }
    &-div-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }
}
