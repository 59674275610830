.documents-panel {
    &-divider {
        height: 63px;
        width: 1px;
        background: #AAB7C4;
        margin-left: 15px;
        margin-right: 15px;
    }

    &-container {
        height: calc(100vh - 65px);
        width: 100%;
        background: rgba(248, 248, 248, 1);
    }

    &-search {
        &-building {
            height: 45px;
            width: 156px;
            border-radius: 10px;
            border: solid 1px #7DA5C2;
            background: white;
        }
    }

    &-arrow-curve {
        height: 28px;
        width: 28px;
        object-fit: contain;
    }

    &-panel {
        width: 100%;
        height: 75vh;
        background: white;
        border-radius: 10px;
        border: solid 1px #D4D4D4;
        overflow: hidden;

        &-divider {
            border-right: solid 1px #AAB7C4;
        }

        &-mobile-nav {
            height: 42px;
            width: 100%;
            border-bottom: solid 1px #CCC;
            padding: 0px 15px;
        }

        &-mobile-arrow {
            height: 15px;
            width: 15px;
            object-fit: contain;
            transform: translateY(2px);
        }
    }

    &-tab {
        height: 50px;
        width: 100%;
        position: relative;
        cursor: pointer;

        &-arrow {
            position: absolute;
            right: 5px;
            height: 15px;
            width: 15px;
            object-fit: contain;

        }
    }

    &-file {
        &-name {
            width: 35%;
        }

        &-person {
            width: 25%;
        }

        &-label {
            width: 15%;
        }

        &-date {
            width: 25%;
        }

        &-menu {
            width: 10%;
            cursor: pointer;
            position: relative;

            &-dot {
                height: 6px;
                width: 6px;
                background: #525252;
                border-radius: 6px;
                margin-top: 2px;
                margin-bottom: 2px;
            }

            &-modal {
                background: white;
                border-radius: 10px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                height: 139px;
                width: calc(100% - 10px);

                &-arrow {
                    height: 15px;
                    width: 23.78px;
                    margin-right: 12.5px;
                    z-index: 1;
                }

                &-container {
                    width: 180px;
                    height: 160px;
                    position: absolute;
                    top: 100%;
                    right: 12.5px;
                    overflow: hidden;
                    align-items: center;
                    z-index: 6;
                }

                &-button { 
                    height: 44px;
                    width: 80%;
                    cursor: pointer;
                }

                &-divider {
                    border-bottom: solid 1px #AAB7C4;
                }
            }
        }

        &-notif {
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #7DA5C2;
            margin-right: 10px;
        }

        &-doc {
            height: 28px;
            width: 28px;
            object-fit: contain;
            margin-right: 15px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .documents-panel {
        &-panel {
            height: calc(100vh - 255px);

            &-mobile {
                &-explorer {
                    height: calc(100% - 42px);
                    width: 100%;
                }

                &-item {
                    height: 42px;
                    width: 100%;
                }
            }
        }
    }
}