.planning {
    &-container{
        height: calc(100vh - 65px);
        width: 100%;
        background-color: #F2F2F2;
        overflow-y: auto;
    }   

    &-calendar {
        &-container {
            width: 750px;
        }
    }

    &-panel {
        &-container {
            width: 307px;
        }
    }

    &-event {
        &-panel {
            height: 710px;
            width: 100%;
            background-color: white;
            border-radius: 10px;
            border: solid 1px rgba(218,218,218,1);
            padding: 20px;
            overflow-y: auto;
            
            &-service {
                height: 100px;
                width: 100%;

                &-border {
                    @extend .planning-event-panel-service;  
                    border-top: solid 1px #AAB7C4;
                }

                &-profile {
                    height: 30px;
                    width: 30px;
                    background-color: #6F6F6F;
                    border-radius: 30px;
                }
            }

            &-menu {
                &-dot {
                    height: 6px;
                    width: 6px;
                    border-radius: 6px;
                    background: #525252;
                }
            }

            &-button {
                height: 42px;
                width: 100%;
                background-color: #7DA5C2;
                border-radius: 10px;
                margin-top: 20px;
                cursor: pointer;
            }

            &-cancel {
                height: 42px;
                width: 100%;
                background-color: #C29A7D;
                border-radius: 10px;
                margin-top: 20px;
                cursor: pointer;
            }

            &-error {
                color: #f44336;
            }
            
            &-new {
                &-radio {
                    height: 20px;
                    width: 20px;
                    border-radius: 19px;
                    border: solid 1.5px #7DA5C2;
                }

                &-input {
                    height: 45px;
                    width: 100%;
                    border-radius:10px;
                    border: solid 1px #DADADA;
                    padding: 15px;
                    overflow: hidden;
                }

                &-service {
                    height: 45px;
                    width: 100%;
                    position: relative;

                    &-input {
                        height: 100%;
                        width: 100%;
                        background: white;
                        border-radius:10px;
                        border: solid 1px #DADADA;
                        padding: 15px;
                        overflow-y: auto;
                        position: absolute;
                    }

                    &-item {
                        height: 45px;
                    }

                    &-arrow {
                        height: 15px;
                        width: 15px;
                        object-fit: contain;
                    }
                }

                &-textarea {
                    height: 130px;
                    width: 100%;
                    border-radius:10px;
                    border: solid 1px #DADADA;
                    padding: 15px;
                    resize: none;
                    
                    &:focus {
                        outline: none;
                    }
                }
            }

            &-modal {
                width: 168px;
                height: 100px;
                background: white;
                border-radius: 10px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                padding: 5px 23px;
                
                &-container {
                    height: 125px;
                    width: 183px;
                    top: calc(100% + 10px);
                    right: -35px;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &-arrow {
                    height: 15px;
                    width: 23.78px;
                    margin-right: 25px;
                    z-index: 1;
                }

                &-button {
                    height: 45px;
                    width: 100%;
                    cursor: pointer;
                }

                &-line { 
                    border-bottom: solid 1px #AAB7C4;
                }
            }
        }

        &-activity {
            &-container {
                height: 50px;
                width: 250px;
                border-top-left-radius: 10px;
                background-color: white;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                position: fixed;
                right: 0%;
                bottom: 0%;
            }
            
            &-icon {
                height: 32px;
                width: 32px;
                object-fit: contain;
            }
        }
    }
}