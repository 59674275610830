.ventilation-service-step1 {
    &-control {
        width: 90%;
        height: 45px;
        border-radius: 10px;
        padding: 1%;
        margin-top: 10px;
        border: 1px solid #DADADA;
    }
    &-input-div {
        width: 25%;
        height: 100%;
        margin-right: 20px;
    }
}
