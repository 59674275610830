.document-details {
    &-dot {
      width: 8px;
      height: 8px;
      margin-left: -60px;
      margin-top: 12px;
      border-radius: 4px;
      background: #7da5c2;
    }
    &-image{
      width: 21px;
      height: 28px;
      margin-left: 15px;
    }
    &-file-name {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      overflow-wrap: break-word;
    }
    &-person-name {
      font-size: 14px;
      color: #4a4a4a;
      overflow-wrap: break-word;
    }
    &-label {
      font-size: 14px;
      color: #4a4a4a;
      overflow-wrap: break-word;
    }
    &-date-time {
      font-size: 14px;
      color: #4a4a4a;
      overflow-wrap: break-word;
    }

    &-dropzone-container {
      min-height: 100px;
      width: 100%;
      margin-top: 25px;
    }
    
  }
  .document-details {
    &-outer-container {
      height: 62vh;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &-inner-container {
      height: auto;
      width: 100%;
    }
  }
  
  .slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 20px;
    background:#7da5c2;
    cursor: pointer;
  }

 .slider {
    -webkit-appearance: none;
    width: 200px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #7da5c2;;
    outline: none;
    margin: 0px 10px 0px 10px;
  }