.project-application-BillInfo-switch-group {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #7da5c2;
  border-radius: 10px;
  width: 188px;
  height: 35px;
}

.project-application-BillInfo-switch-active {
  width: 75px;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #7da5c2;
  border-radius: 10px;
}

.project-application-BillInfo-switch-inactive {
  width: 113px;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #000000;
  border-radius: 10px;
}
.project-application-billinfo-edit {
  width: 140px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  &-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }
  &-image {
    width: 11.52px;
    height: 11.52px;
  }
}
.project-application-billinfo-input-box {
  //   width: 200px;
  //   height: 71px;
  &-label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 38px;
    color: #6f6f6f;
  }
  &-input {
    width: 390px;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 10px;
  }
  &-error-border {
    border: 1px #f30c0c solid;
  }
}
.project-application-billinfo {
  display: flex;
  flex-direction: row;
  &-adres-box {
    width: 210px;
    height: 71px;
    &-input {
      width: 210px;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 10px;
    }
    &-error-border {
      border: 1px #f30c0c solid;
    }
  }
  &-nr-box {
    width: 80px;
    height: 71px;
    &-input {
      width: 80px;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 10px;
      margin-left: 10px;
    }
    &-error-border {
      border: 1px #f30c0c solid;
    }
  }
  &-bus-box {
    &-input {
      width: 80px;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 10px;
    margin-left: 20px;
    }
    &-error-border {
      border: 1px #f30c0c solid;
    }
  }
  &-postcode-box {
    width: 190px;
    height: 71px;
    &-input {
      width: 190px;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 10px;
    }
    &-error-border {
      border: 1px #f30c0c solid;
    }
  }
  &-gemeente-box {
    width: 190px;
    height: 71px;
    &-input {
      width: 190px;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 10px;
      margin-left: 10px;

    }
    &-error-border {
      border: 1px #f30c0c solid;
    }
  }
}
.project-application-billinfo-submit {
  width: 345px;
  height: 45px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  background: #7da5c2;
  border-radius: 10px;
}
.project-application-billinfo-second-invoice-box {
  width: 50%;
  height: 129px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  &-image {
    width: 35px;
    height: 35px;
    background: #6f6f6f;
    border-radius: 20px;
    padding: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .project-application-billinfo-edit {
    width: 100px;
    font-size: 10px;
    &-image {
      width: 10px;
    }
  }
  .project-application-billinfo-input-box {
    width: 100px;

    &-input {
      width: 280px;
    }
  }
  .project-application-billinfo {
    &-adres-box {
      width: 120px;
      &-input {
        width: 120px;
      }
    }
    &-nr-box {
      width: 80px;
      &-input {
        width: 80px;
      }
    }
    &-bus-box {
      width: 80px;
      &-input {
        width: 80px;
      }
    }
    &-postcode-box {
      width: 130px;
      &-input {
        width: 130px;
      }
    }
    &-gemeente-box {
      width: 100px;
      &-input {
        width: 150px;
      }
    }
  }
  .project-application-billinfo-second-invoice-box {
    width: 100px;
    height: 100px;
    align-items: center;
  }
}

@media only screen and (max-width: 337px) {
  .project-application-BillInfo-switch-group {
    height: 50px;
  }
}