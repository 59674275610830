.invoice-new {
    &-service {
        width: 100%;
        height: 35px;
        padding: 0px 15px;
        cursor: pointer;

        &-container {
            width: 100%;
            border-radius: 10px;
            border: solid 1px #DADADA;
            overflow: hidden;
        }

        &-arrow {
            height: 14px;
            width: 14px;
            object-fit: contain;
        }
    }

    &-success {
        height: 35px;
    }

    &-create {
        height: 35px;
        width: 100%;
        background-color: #7DA5C2;
        border-radius: 10px;
        cursor: pointer;
    }
}