.stepsix {
    &-dropdown {
        &-container {
            min-height: 237px;
            height: auto;
            width: 100%;
            border-radius: 15px;
            border: solid 2px #DADADA;
            margin-top: 25px;
        }
    }

    &-arrow{
        &-container {
            height: 32px;
            width: 32px;
            position: absolute;
            left: 100%;
            top: 50%;
        }
    }

    &-document {
        &-icon {
            height: 30px;
            width: 30px;
            object-fit: contain;
        }

        &-close {
            &-icon {
                height: 14px;
                width: 14px;
                object-fit: contain;
            }
        }

        &-container {
            min-height: 200px;
            height: auto;
            width: 100%;
        }
    }

    &-import {
        height: 35px;
        width: 85px;
        border-radius: 10px;
        border: solid 1px #DCDCDC;
        cursor: pointer;

        &-icon {
            height: 15px;
            width: 15px;
            object-fit: contain;
        }
    }

    &-button {
        &-submit {
            height: 45px;
            width: 345px;
            border-radius: 10px;
            background: #7DA5C2;
            cursor: pointer;

            &-icon {
                height: 22px;
                width: 22px;
                object-fit: contain;
                position: absolute;
                right: 5%;
            }
        }
    }
}