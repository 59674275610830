.custom-popup {
    &-main-div {
        padding: 5%;
    }
    &-success-div {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #00A652;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-success-icon {
        height: 15px;
    }
    &-error-div {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #E67B88;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
