.services {
    &-container {
        height: calc(100vh - 65px);
        width: 100%;
        background: white;
    }

    &-radio {
        &-outline {
          width: 15px;
          height: 15px;
          border: 2px solid #7DA5C2;
          border-radius: 10px;
          background-color: #ffffff;
          left: -4px;
          top: -6px;
        }
        &-filled {
          width: 15px;
          height: 15px;
          background: #7DA5C2;
          border-radius: 10px;
          left: -4px;
          top: -6px;

        }
    }

    &-tab {
        height: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0px 25px;

        &-container {
            width: 99%;
            height: 60px;
            display: flex;
            margin-top: 25px;
            border-bottom: solid 3px #7DA5C2;
        }

        &-icon {
            height: 15px;
            width: 15px;
            object-fit: contain;
            margin-left: 10px;
        }
    }

    &-search {
        &-container {
            height: 40px;
            max-width: 329px;
            width: 100%;
            border: solid 1px #DCDCDC;
            border-radius: 10px;
            background: white;
        }

        &-icon {
            height: 20px;
            width: 20px;
        }
    }

    &-title {
        &-icon{ 
            height: 18px;
            width: 18px;
            object-fit: contain;
        }
    }

    &-category {
        &-item {
            &-container {
                min-height: 200px;
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                padding-top: 5px;
            }
        }
    }
    
    &-filter {
        height: 100%;
        width: 100%;
        border: solid 1px #DCDCDC;
        border-radius: 10px;
        background: white;
        cursor: pointer;

        &-container {
            height: 40px;
            max-width: 100px;
            width: 100%;
            position: relative;
            z-index: 9999999;
        }

        &-arrow {
            height: 12px;
            width: 12px;
            object-position: bottom;
            object-fit: contain;
        }

        &-selection {
            height: calc(100% - 20px);
            width: 100%;
            background: white;
            border-radius: 10px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
            padding: 5%;

            &-container {
                height: 359px;
                width: 725px;
                position: absolute;
                top: calc(100%);
                left: 0%;
                padding-left: 5%;
                padding-right: 5%;
                outline: none;
            }

            &-arrow {
                height: 15px;
                width: 23.78px;
                margin-left: 25px;
                z-index: 1;
            }

            &-line {
                height: 1px;
                background: #AAB7C4;
                width: 100%;
            }
        }
    }

    &-form {
        height: 42px;
        width: 155px;
        border-radius: 10px;
        background-color: #7DA5C2;
    }

    &-details {
        height: 45px;
        border: solid 1px #D3D3D3;
        border-radius: 3px;

        &-container {
            height: calc(100vh - 65px);
            width: 100%;
            overflow: hidden;
        }

        &-icon {
            height: 15px;
            width: 15px;
            object-fit: contain;
            margin-right: 5px;
        }
        
        &-name {
            min-width: 129px;
            width: 12.5%;
            margin-right: 2px;

            &-box {
                @extend .services-details-name;
                @extend .services-details;
            }

            &-color { 
                height: 100%;
                width: 7px;
                background: #B2D5FF;
                margin-right: 7px;
            }

            &-filter {
                height: calc(100% - 25px);
                width: 220px;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                padding: 5%;

                &-container {
                    position: absolute;
                    top: 100%;
                    left: 0px;
                    width: 230px;
                    height: 820px;
                    z-index: 12;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: hidden;
                    outline: none;
                }

                &-arrow {
                    height: 15px;
                    width: 23.78px;
                    margin-left: 25px;
                    z-index: 1;
                }

                &-bubble {
                    height: 12px;
                    width: 12px;
                    border-radius: 500px;
                }
                
                &-line {
                    height: 2px;
                    width: 138px;
                    background: #AAB7C4;
                    margin: 10px 0px;
                }
            }
        }

        &-file {
            min-width: 65px;
            width: 7.5%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-file;
                @extend .services-details;
            }
        }

        &-file-name {
            min-width: 120px;
            width: 10%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-file-name;
                @extend .services-details;
            }
        }

        &-customer {
            min-width: 109px;
            width: 10%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-customer;
                @extend .services-details;
            }
        }

        &-responsible {
            min-width: 122px;
            width: 10%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-responsible;
                @extend .services-details;
            }

            &-filter {
                height: calc(100% - 25px);
                width: 220px;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                padding: 5%;

                &-container {
                    position: absolute;
                    top: 100%;
                    left: -25px;
                    width: 230px;
                    height: auto;
                    z-index: 12;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    outline: none;
                    transform-origin: top;

                    @media (max-width: 600px) {
                        left: 0px;
                    }
                }

                &-arrow {
                    height: 15px;
                    width: 23.78px;
                    margin-left: 25px;
                    z-index: 1;
                }
            }
        }

        &-doc {
            min-width: 45px;
            width: 2.5%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-doc;
                @extend .services-details;
            }
        }

        &-message {
            min-width: 45px;
            width: 2.5%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-message;
                @extend .services-details;
            }
        }

        &-price {
            min-width: 90px;
            width: 10%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-price;
                @extend .services-details;
            }
        }

        &-date {
            min-width: 74px;
            width: 7.5%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-date;
                @extend .services-details;
            }

            &-filter {
                height: auto;
                width: 260px;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
                padding: 5%;

                &-container {
                    position: absolute;
                    top: 100%;
                    left: 0px;
                    width: 270px;
                    height: 410px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: hidden;
                    outline: none;
                }

                &-arrow {
                    height: 15px;
                    width: 23.78px;
                    margin-left: 25px;
                    z-index: 1;
                }

                &-input {
                    height: 45px;
                    border: solid 1px #DADADA;
                    border-radius: 10px;
                    width: 75px;

                    &-sub {
                        width: 20px;
                        text-align: center;
                    }
                }

                &-save {
                    margin-top: 10px;
                    height: 37px;
                    width: 100%;
                    border-radius: 10px;
                    background-color: #7DA5C2;
                }
            }
        }

        &-timeline {
            min-width: 106px;
            width: 10%;
            margin-right: 2px;
            margin-left: 2px;

            &-box {
                @extend .services-details-timeline;
                @extend .services-details;
            }
        }

        &-icons {
            width: 45px;
            margin-right: 2px;
            margin-left: 2px;
            cursor: pointer;

            &-box {
                @extend .services-details-icons;
                @extend .services-details;
            }
        }

        &-delete{
            width: 40px;
            height: 40px;
        }
    }

    &-checkbox {
        height: 20px;
        width: 20px;
        cursor: pointer;

        &-empty {
            border: solid 2px #7DA5C2;
            height: 100%;
            width: 100%;
            border-radius: 2px;
        }
    }

    &-scrolltop {
        height: 50px;
        width: 50px;
        border-radius: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background: #193E5D;
        position: absolute;
        bottom: 25px;
        right: 25px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        &-arrow {
            height: 24px;
            width: 24px;
            object-fit: contain;
        }
    }
    &-vertical-line{
        height: 100%;
        width: 1px;
        background-color: #dedede;
    }

    &-loader {
        height: 30px;
        width: 30px;
        object-fit: contain;
    }

    &-status-display {
        color: #4CAF50;

        &-container {
            height: 55px;
            width: auto;
            background-color: white;
            border-radius: 5px;
            position: absolute;
            top: 80px;
            right: 25px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
            padding: 15px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .services{
        &-details{
            &-name{
                &-box{
                    width: auto;
                    height: 230px;
                    margin-top: 10px;
                    border-radius: 5px;
                }
            }
            &-timeline{
                &-box{
                    width: 20%;
                    // height: 100%;
                }
            }
        }
        &-form{
            width: 40px;
            height: 40px;
            border-radius: 20px;
        }
    }
}

.services-pagination {
    &-item {
        height: 100%;
        width: 100%;
        background-color: #7DA5C2;
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        opacity: 0.3;

        &-container {
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
        }
    }
}

.pagination-arrow-left {
    height: 15px;
    width: 15px;
    object-fit: contai
}

.pagination-arrow-right {
    height: 15px;
    width: 15px;
    object-fit: contain;
    transform: rotate(180deg);
}

.service-title-container {
    // box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
    box-shadow: 0 4px 6px -2px rgba(198, 198, 198, 0.5);

    height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
    flex-shrink: 0;
    align-items: center;
}