@font-face {
  font-family: 'Vietnam';
  font-weight: 100;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 100;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 300;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 300;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 400;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 300;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 500;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 500;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 600;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 600;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 700;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 700;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 800;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Vietnam';
  font-weight: 800;
  font-style: italic;
  src: local('Vietnam'),
    url(../assets/fonts/BeVietnam-ExtraBoldItalic.ttf) format('truetype');
}
