.client {
    &-main-div {
        padding: 10px 20px;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 65px);
        background-color: rgba(248,248,248,1)
    }
    &-child-div {
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 10px 15px;
        border: 1px solid #DADADA;
    }
    &-add-button {
        height: 40px;
        width: 140px;
        border-radius: 8px;
        border: 1px solid #DCDCDC;
        cursor: pointer;
    }
    &-button-icon {
        height: 11px;
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
    }
    &-tab-main {
        width: 200px;
        height: 40px;
        border: 1px solid #7DA5C2;
        border-radius: 8px;
        display: flex;
    }
    &-tab-add {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    &-tab-import {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        cursor: pointer;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &-tab-active {
        background-color: #7DA5C2;
        color: #ffffff;
    }
    &-tab-inactive {
        background-color: #ffffff;
        color: #6F6F6F;
    }
    &-button-blue {
        background-color: #7DA5C2;
        color: #ffffff;
        border-radius: 8px;
        width: auto;
        height: 42px;
        padding: 8px;
        cursor: pointer;
    }
    &-textarea {
        height: 100px;
        border: 1px #DADADA solid;
        width: 100%;
        border-radius: 10px;
        color: #000000;
        padding: 6px 6px;
        resize: none;
    }
    &-edit-button {
        height: 40px;
        width: 77px;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        cursor: pointer;
    }
}