.dashboard {
    &-sidebar {
        &-container {
            height: 100vh;
            width: 100%;
            background: white;
            box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
            z-index: 5;

            &-modular {
                height: 100vh;
                width: 250px;
                background: white;
                box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
                z-index: 5;
            }
        }

        &-inset {
            max-width: 200px;
            width: 100%;
        }

        &-logo {
            height: 36px;
            width: 36px;
        }

        &-line {
            height: 1px;
            width: 90%;
            background: #BAC4CB;
        }
        &-tab {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 5px;
            cursor: pointer;
            transition-duration: 0.4s;

            &-top {
                @extend .dashboard-sidebar-tab;
                margin-top: 25px;
            }

            // &:hover {
            //     background: #E5EDF3;
            // }
            &:hover{
                background: rgb(229, 237, 243);
            }
        }

        &-main-container {
            background-color: #f8f8f8;
        }

        &-expandable {
            &-arrow {
                height: 13px;
                width: 13px;
                object-fit: contain;
                object-position: center;
                position: absolute;
                right: 15px;
            }
        }
    }

    &-modular {
        &-close {
            height: 14px;
            width: 14px;
            object-fit: contain;
        }
    }
}


@media only screen and (max-width: 900px) {
    .dashboard{
        &-sidebar{
            &-logo {
                width: 30px;
                height: 30px;
            }
        }
    }
}
