.step-three {
  &-description {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
  &-other-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }

  &-upload-button-1 {
    width: 272px;
    cursor: pointer;
   }
   &-upload-button-2{
    width: 168px;
    cursor: pointer;
   }
  &-file-icon {
    width: 29px;
    height: 39px;
  }
  &-file-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }
  &-delete-image {
    width: 35px;
    height: 35px;
  }
}
