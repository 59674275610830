.document-selector {
    &-container {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 99999;
    }

    &-modal {
        height: 90%;
        width: 100%;
        border-radius: 10px;
        background-color: white;
        padding: 25px;

        &-close {
            cursor: pointer;
        }

        &-send {
            height: 40px;
            width: 100%;
            max-width: 300px;
            background-color: #7DA5C2;
            border-radius: 10px;
            cursor: pointer;
        }

        &-documents {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            padding: 20px 0px;
            overflow-y: auto;
        }

        &-item {
            height: 50px;
            width: 100%;
            display: flex;
            align-items: center;
        }

        &-check {
            &-container {
                height: 25px;
                width: 25px;
                border-radius: 25px;
                border: solid 2px #7DA5C2;
                cursor: pointer;
            }

            &-icon {
                height: 12px;
                width: 12px;
                object-fit: contain;
                object-position: center;
            }
        }

        &-type {
            height: 25px;
            width: 25px;
            object-fit: contain;
            margin-left: 15px;
        }
    }
}