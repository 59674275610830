@import './lib/margin.scss';
@import './lib/breakpoints.scss';
@import './lib/font.scss';
@import './lib/display.scss';
@import './lib/buttons.scss';
@import './lib/size.scss';
@import './lib/animatable.scss';
@import './lib/padding.scss';

h1 {
  margin: 0px;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
}

span {
  display: flex;
}

input {
  border: none;
}

input:focus {
  outline: none;
}

button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: none;
}

.outline {
  &-none {
    outline: none;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Vietnam', 'Helvetica', sans-serif;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.vietnam-regular {
  font-family: 'vietnam', 'Helvetica', sans-serif;
  font-weight: 400;
}

.vietnam-medium {
  font-family: 'vietnam', 'Helvetica', sans-serif;
  font-weight: 500;
}

.vietnam-semibold {
  font-family: 'Lato', 'Helvetica', sans-serif;
  font-weight: 600;
}

.vietnam-bold {
  font-family: 'Lato', 'Helvetica', sans-serif;
  font-weight: 700;
}

.main-font {
  font-family: 'Vietnam'; // just replace the font with your desired choice

  &-thin {
    @extend .main-font;
    font-weight: 100;
  }

  &-light {
    @extend .main-font;
    font-weight: 300;
  }

  &-regular {
    @extend .main-font;
    font-weight: 400;
  }

  &-medium {
    @extend .main-font;
    font-weight: 500;
  }

  &-semi-bold {
    @extend .main-font;
    font-weight: 600;
  }

  &-bold {
    @extend .main-font;
    font-weight: 700;
  }

  &-semi-black {
    @extend .main-font;
    font-weight: 800;
  }

  &-black {
    @extend .main-font;
    font-weight: 900;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-disable {
    cursor: not-allowed;
  }
}
