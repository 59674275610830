.safety-step-one {
  &-add-image {
    &-size {
      width: 20px;
      height: 20px;
    }
    width: 35px;
    height: 35px;
    margin-right: -5px;
    min-width:35px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: #0066a0;
  }
  &-delete-image {
    width: 35px;
    height: 35px;
    margin-left: -5px;
    margin-bottom: 5px;

  }
}
